import React, { useState } from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import { Link } from '@inertiajs/react';
import { Button } from "@/Components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/Components/ui/dropdown-menu";
import { User, Dog, Calendar, CreditCard, DoorOpen, User2, Menu, X } from 'lucide-react';
import { User as UserType } from '@/types'
import ApplicationLogoNormal from '@/Components/ApplicationLogoNormal';

interface HeaderProps {
    user?: UserType | null;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <header className="flex items-center justify-between px-4 py-3 bg-white shadow-md relative">
                {/* Logo */}
                <div className="flex items-center">
                    <a href='/'>
                        <ApplicationLogoNormal className="w-28 pb-2 ml-2" />
                    </a>
                </div>

                {/* Desktop Navigation Menu */}
                <nav className="hidden md:flex space-x-8">
                    <a href="/how-it-works" className="text-gray-600 hover:text-gray-900">How It Works</a>
                    <a href="/locations" className="text-gray-600 hover:text-gray-900">Our Fields</a>
                    <a href="/gift-cards" className="text-gray-600 hover:text-gray-900">Gift Cards</a>
                    <a href="/start-a-field" className="text-gray-600 hover:text-gray-900">Start A Field</a>
                </nav>

                {/* Mobile Menu Button */}
                <button
                    className="md:hidden p-2 text-gray-600"
                    onClick={toggleMobileMenu}
                >
                    {isMobileMenuOpen ? (
                        <X className="h-6 w-6" />
                    ) : (
                        <Menu className="h-6 w-6" />
                    )}
                </button>

                {/* Auth Buttons or User Dropdown */}
                <div className="hidden md:flex space-x-4">
                    {user ? (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline" className="flex items-center space-x-2 cursor-pointer">
                                    <User className="w-4 h-4" />
                                    <span>{user.name}</span>
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-56">
                                <Link href="/account/membership">
                                    <DropdownMenuItem className="cursor-pointer">
                                        <User className="mr-2 h-4 w-4" />
                                        <span>My Profile</span>
                                    </DropdownMenuItem>
                                </Link>
                                <Link href="/account/dogs">
                                    <DropdownMenuItem className="cursor-pointer">
                                        <Dog className="mr-2 h-4 w-4" />
                                        <span>My Dogs</span>
                                    </DropdownMenuItem>
                                </Link>
                                <Link href="/account/history">
                                    <DropdownMenuItem className="cursor-pointer">
                                        <Calendar className="mr-2 h-4 w-4" />
                                        <span>My Bookings</span>
                                    </DropdownMenuItem>
                                </Link>
                                <Link href="/account/methods">
                                    <DropdownMenuItem className="cursor-pointer">
                                        <CreditCard className="mr-2 h-4 w-4" />
                                        <span>My Payment Methods</span>
                                    </DropdownMenuItem>
                                </Link>

                                {user.type !== 'customer' && (
                                    <>
                                        <DropdownMenuSeparator />
                                        <Link href="/dashboard">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <User2 className="mr-2 h-4 w-4" />
                                                <span>Go To Admin</span>
                                            </DropdownMenuItem>
                                        </Link>
                                    </>
                                )}

                                {user.is_franchise_user && (
                                    <>
                                        <DropdownMenuSeparator />
                                        <Link href="/franchisee">
                                            <DropdownMenuItem className="cursor-pointer">
                                                <User2 className="mr-2 h-4 w-4" />
                                                <span>Go To Franchise Admin</span>
                                            </DropdownMenuItem>
                                        </Link>
                                    </>
                                )}

                                <DropdownMenuSeparator />
                                <Link href="/logout">
                                    <DropdownMenuItem className="cursor-pointer">
                                        <DoorOpen className="mr-2 h-4 w-4" />
                                        <span>Logout</span>
                                    </DropdownMenuItem>
                                </Link>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    ) : (
                        <>
                            <Link href="/login">
                                <Button className='bg-brand text-white border-1' variant="outline">Login</Button>
                            </Link>
                            <Link href="/register">
                                <Button className='bg-dark'>Register</Button>
                            </Link>
                        </>
                    )}
                </div>
            </header>

            {/* Mobile Menu */}
            <div className={`md:hidden fixed inset-0 bg-brand z-50 transition-transform duration-300 ease-in-out ${isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                {/* Close button */}
                <button
                    onClick={toggleMobileMenu}
                    className="absolute top-4 right-4 text-white p-2"
                >
                    <X className="h-6 w-6" />
                </button>

                <div className="flex flex-col h-full pt-14">
                    <nav className="flex flex-col space-y-2 px-4 overflow-y-auto max-h-[calc(100vh-3.5rem)]">
                        <a href="/how-it-works" className="text-white text-lg py-2 border-b border-white/20">
                            How It Works
                        </a>
                        <a href="/locations" className="text-white text-lg py-2 border-b border-white/20">
                            Our Fields
                        </a>
                        <a href="/gift-cards" className="text-white text-lg py-2 border-b border-white/20">
                            Gift Cards
                        </a>
                        <a href="/start-a-field" className="text-white text-lg py-2 border-b border-white/20">
                            Start A Field
                        </a>

                        {user ? (
                            <>
                                <Link href="/account/membership" className="text-white text-lg py-2 border-b border-white/20">
                                    My Profile
                                </Link>
                                <Link href="/account/dogs" className="text-white text-lg py-2 border-b border-white/20">
                                    My Dogs
                                </Link>
                                <Link href="/account/history" className="text-white text-lg py-2 border-b border-white/20">
                                    My Bookings
                                </Link>
                                <Link href="/account/methods" className="text-white text-lg py-2 border-b border-white/20">
                                    My Payment Methods
                                </Link>

                                {user.type !== 'customer' && (
                                    <Link href="/dashboard" className="text-white text-lg py-2 border-b border-white/20">
                                        Go To Admin
                                    </Link>
                                )}

                                {user.is_franchise_user && (
                                    <Link href="/franchisee" className="text-white text-lg py-2 border-b border-white/20">
                                        Go To Franchise Admin
                                    </Link>
                                )}

                                <Link href="/logout" className="text-white text-lg py-2 border-b border-white/20">
                                    Logout
                                </Link>
                            </>
                        ) : (
                            <div className="flex flex-col space-y-3 mt-3 mb-6">
                                <Link href="/login">
                                    <Button className="w-full bg-white text-brand hover:bg-white/90">
                                        Login
                                    </Button>
                                </Link>
                                <Link href="/register">
                                    <Button className="w-full bg-dark hover:bg-dark/90">
                                        Register
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Header;
